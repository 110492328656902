<template>
  <div>
    <!-- ======  nav  ====== -->
    <!-- <div class="all">
      <div class="container">
        <div class="nav">
          <div class="nav-left">
            <img src="@/assets/home/logo.png" @click="toHome">
            <span class="nav-left-xian"> | </span>
            <span class="nav-left-pay">订单支付</span>
          </div>

          <div class="nav-right">
            <span class="nav-right-name">{{$store.getters.enterpriseObj.name}}</span>
            <img src="@/assets/home/logo.png">
          </div>
        </div>
      </div>
    </div> -->
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <!-- ======  body  ====== -->
    <div class="container">
      <div class="order">
        <div class="order-top">
          <div>
            <span>订单编号：</span>
            <span class="order-top-num">{{$route.params.orderSn}}</span>
          </div>
          <div class="order-top-money">
            <span>支付金额：</span>
            <span class="money">￥{{this.$route.params.totalAmount}}.00</span>
          </div>
          <span class="order-note">注意：请在提交订单后 30分钟 内付款，否则订单会被取消</span>

        </div>

        <div class="order-qrcode" >
          <div class="order-qrcode-left">
            <h2>{{checkPay}}支付</h2>
            <div class="order-code">
              <img :src="imgSrc">
            </div>
            <div  class="order-code-sao" :style="checkPay=='微信'?'background-color: #03B203;':'background-color: #1578ff;'">
              <img src="../../assets/certificateList/icon-saoyisao.png">
              <div class="order-code-sao-zi">
                <span>打开{{checkPay}}扫一扫</span>
                <!-- <span>扫描二维码支付</span> -->
              </div>
            </div>
          </div>
          <!-- <img class="iphone" src="../../assets/certificateList/img-wxpay.png"> -->
        </div>
      </div>
    </div>
    <footerBottom></footerBottom>

  </div>
</template>

<script>
import { httpGet, httpNPGet,httpPost } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import footerBottom from "@/components/footer/footer.vue"
export default {
  name: "orderpayment",
  data(){
    return{
      imgSrc:"",
      userName:"",
      timer:"",
      checkPay:"",
      pageIndex: 2,
    };
  },
  components: {
    topNav,
    footerBottom
  },
  methods:{
    // 回首页
    toHome(){
      this.$router.push("/")
    },
    // 上传视频播放时间开始
    getOrderMsgBegin(){
      this.timer = setInterval(this.getOrderMsg, 2000);
    },
    // 上传视频播放时间开始
    getOrderMsgEnd(){
     clearInterval(this.timer);
    },
    // 获取订单信息
    getOrderMsg(){
      if(this.$route.params.checkPay==2){
        httpGet("/pay/wx/query",
          {
            orderSn: this.$route.params.orderSn
          },(res)=>{
            // console.log(res)
            clearInterval(this.timer);
            this.$router.push(
              {
                name:"Payok",
                params:{
                  orderSn: this.$route.params.orderSn,
                  checkPay: this.$route.params.checkPay,
                  certificateMsg: this.$route.params.certificateMsg,
                  totalAmount:this.$route.params.totalAmount,
                }
              }
            )
          },(err)=>{
             
            // throw err
          }
        )
      }else if(this.$route.params.checkPay==1){
        httpGet("/pay/ali/query",
          {
            orderSn: this.$route.params.orderSn
          },(res)=>{
            clearInterval(this.timer);
            this.$router.push(
              {
                name:"Payok",
                params:{
                  orderSn: this.$route.params.orderSn,
                  checkPay: this.$route.params.checkPay,
                  certificateMsg: this.$route.params.certificateMsg,
                  totalAmount:this.$route.params.totalAmount,
                }
              }
            )
          },(err)=>{
             
            throw err
          }
        )
      }else if(this.$route.params.checkPay==0){
        httpGet("/pay/ali/query",
          {
            orderSn: this.$route.params.orderSn
          },(res)=>{
            clearInterval(this.timer);
            this.$router.push(
              {
                name:"Payok",
                params:{
                  orderSn: this.$route.params.orderSn,
                  checkPay: this.$route.params.checkPay,
                  certificateMsg: this.$route.params.certificateMsg,
                  totalAmount:this.$route.params.totalAmount,
                }
              }
            )
          },(err)=>{
             
            throw err
          }
        )
      }
    }
  },
  created(){
    if(this.$route.params.imgSrc){
      this.imgSrc = this.$route.params.imgSrc
      this.userName = localStorage.getItem("userName")
    }
    if(this.$route.params.checkPay==2){
      this.checkPay = "微信"
    }else if(this.$route.params.checkPay==1){
      this.checkPay = "支付宝"
    }else if(this.$route.params.checkPay==0){
      this.checkPay = "银联"
    }
  },
  mounted(){
    this.getOrderMsgBegin()
  }
}
</script>

<style lang="scss" scoped>
//.logo {
//  width: 58px;
//  height: 58px;
//}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.all {
  background-color: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
}

.nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //background-color: #42b983;

  .nav-left {
    display: flex;
    align-items: center;
    //background-color: #99a9bf;
    height: 80px;
    line-height: 80px;

    img {
      display: block;
      width: 60px;
      height: 60px;
    }

    .nav-left-xian {
      padding: 0 24px;
      color: #D9D9D9;
    }

    .nav-left-pay {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    height: 80px;
    line-height: 80px;

    .nav-right-name {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #353535;
      padding-right: 10px;
    }

    img {
      display: block;
      width: 60px;
      height: 60px;
    }
  }
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  background-color: #fff;

  .order-top {
    text-align: center;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-around;
    margin-top: 10px;
    padding: 10px;
    width: 558px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    border-bottom: 1px solid #EDEFF2;

    .order-top-num {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
    }

    .order-top-money {
      padding: 10px 0;

      .money {
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #EB5757;
      }
    }

    .order-note {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ef4136;

    }
  }

  .order-qrcode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 526px;
    height: 362px;
    margin-top: 32px;
    //background-color: #99a9bf;

    .order-qrcode-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 350px;
      // width: 208px;
      padding: 30px;
      .order-code {
        width: 208px;
        height: 208px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        border: 1px solid #E2E2E2;

        img {
          width: 184px;
          height: 184px;
        }
      }

      .order-code-sao {
        display: flex;
        align-items: center;
        padding: 12px 15px;
        border-radius: 5px;
        width: 210px;
        box-sizing: border-box;
        img {
          display: block;
          width: 37px;
          height: 37px;
        }

        .order-code-sao-zi {
          padding-left: 14px;
          border-radius: 5px;
          span {
            font-size: 15px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }

    .iphone {
      display: block;
      width: 282px;
      height: 362px;
    }
  }

}


.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}
</style>